import { printingReceiptService, backendReceiptService } from '@/api';
import { Decimal } from 'decimal.js';
import { ProductionOrderProductResource } from '../../../resource/model/production-management/production-order-product';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { translation, messageError } from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { DialogMixin } from '@/mixins/dialog';

@Component({
  name: 'update-process-progress-dialog'
})
export default class UpdateProcessProgressDialog extends mixins(DialogMixin) {
  /**
   * 显示控制
   */
  @PropSync('visible') public syncedVisible!: boolean;

  @Prop({
    type: Array,
    default: () => []
  }) 
  public selectedList!: Array<ProductionOrderProductResource>;

  /**
   * 提交loading状态
   */
  public submitLoading = false;

  @Prop({
    type: String,
    default: 'print'
  })
  public processType: 'print' |  'backend' = 'print';

  /**
   * 表单数据对象
   */
  public formData: Partial<{
    selectedList: Array<ProductionOrderProductResource>;
    updatePercent: number;
    updateArea: number;
  }> = {
    selectedList: [],
    updatePercent: 0,
    updateArea: 0
  };

  /**
   * 获取选中项次总面积
   */
  public get totalArea(): number {
    return this.selectedList.reduce((pre, cur) => {
      return pre + cur.totalArea;
    }, 0);
  }

  /**
   * 获取选中项次已完成面积
   */
  public get finishedArea(): number {
    return this.selectedList.reduce((pre, cur) => {
      if (this.processType === 'print') {
        return pre + cur.printFinishedArea || 0;
      }

      return pre + cur.backendFinishedArea || 0;
    }, 0);
  }

  /**
   * 计算已完成面积占比
   */
  public get finishedPercent(): number {
    return Math.round(this.finishedArea / this.totalArea * 100);
  }

  @Watch('formData.updatePercent')
  public handleUpdatePercent(val: number): void {
    this.formData.updateArea = Number(new Decimal(this.totalArea).mul((new Decimal(val))).div(100));
  }

  /**
   * 提交
   */
  public async handleSubmit(): Promise<void> {
    if (this.finishedPercent === this.formData.updatePercent) {
      Message.warning(translation('updateProgressDialog.percentValueNotChanged'));
      return;
    }

    const dataList = this.assembleData();

    this.setLoading(true);
    try {
      if (this.processType === 'print') {
        await printingReceiptService.updatePrintingProgress({list: dataList});
      } else {
        await backendReceiptService.updateBackendProgress({list: dataList});
      }
      
      Message.success(translation('operationRes.operationSuccess'));
      this.closeDialog();

      this.$emit('success');
    } catch (error) {
      messageError(error);
    } finally {
      this.setLoading(false);
    }
  }

  public dialogOpened(): void {
    this.formData.updatePercent = this.finishedPercent + 1;
  }

  public dialogClosed(): void {
    this.formData.updatePercent = 0;
  }

  /**
   * 计算进度更新数据
   * @returns 更新数据集合
   */
  private assembleData(): Array<any> {
    const processCurFinishedArea = this.processType === 'print' ? 'printFinishedArea' : 'backendFinishedArea';

    // 过滤出待分配项次，条件：进度百分比 < 目标进度百分比
    const availableItems = this.selectedList.filter(item => {
      return Math.round((item[processCurFinishedArea] || 0) / item.totalArea * 100) < Number(this.formData.updatePercent);
    });

    return availableItems.map(item => {
      // 计算项次本次完成面积 = 预计完成总面积 - 已完成面积，预计完成总面积 = 本项次总面积 * 目标百分比
      const updateArea = new Decimal(Number(this.formData.updatePercent)).div(100).mul(item.totalArea).minus(item[processCurFinishedArea]);

      // 计算项次已完成面积
      const finishedArea = (updateArea.add(item[processCurFinishedArea] || 0)).comparedTo(item.totalArea) === 1 
      ? new Decimal(item.totalArea) : updateArea.add(item[processCurFinishedArea] || 0);

      return {
        id: item.id,
        finishedArea: finishedArea.toNumber(),
        updateArea: updateArea.toNumber(),
      };
    });
  }

}
